import PropTypes from "prop-types";

import { twMerge } from "tailwind-merge";
import { forwardRef } from "react";

const Panel = forwardRef(({ className = "", ...props }, ref) => (
  <div
    ref={ref}
    className={twMerge(
      "bg-white shadow-md rounded-md overflow-hidden",
      className,
    )}
    {...props}
  />
));

Panel.displayName = "Panel";

Panel.propTypes = {
  className: PropTypes.string,
};

export default Panel;
